import React from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO } from 'components';
import { Home } from 'containers';
const IndexPage = () => (
  <Layout>
    <SEO title='Family Practice Clinic ' />
    <Home />
  </Layout>
);

export default IndexPage;
// export const query = graphql`
//   {
//     allFile(
//       filter: { sourceInstanceName: { eq: "content" }, name: { glob: "*news" } }
//     ) {
//       edges {
//         node {
//           childMarkdownRemark {
//             fields {
//               slug
//             }
//             frontmatter {
//               description
//               date(formatString: "dddd, MMMM Do YYYY")
//               title
//             }
//           }
//         }
//       }
//     }
//   }
// `;